<template>
  <v-container
    class="fill-height"
    :class="isPresensi ? 'background-green' : 'background-blue'"
    fluid
  >
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6" lg="4" xl="4" xs="12">
        <v-card-actions class="justify-center">
          <v-avatar color="white" class="my-10 pa-2" height="100" width="100">
            <v-img
              :src="require('@/assets/images/logo-tutwuri.png')"
              contain
              height="70"
            />
          </v-avatar>
        </v-card-actions>
        <p class="text-center ma-0 white--text">
          APLIKASI {{ isPresensi ? "PRESENSI" : "SIMPEG" }}
        </p>
        <p class="text-center font-weight-bold white--text">
          PAUD DIKDAS DIKMEN
        </p>
        <v-card class="rounded-lg mt-15">
          <v-card-text class="pt-5 px-0">
            <v-form
              id="check-login-form"
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="onSubmit(username, email)"
            >
              <v-row justify="center" class="px-4">
                <v-col cols="12" class="py-0">
                  <v-text-field
                    solo
                    flat
                    v-model="username"
                    :rules="usernameRules"
                    placeholder="Username"
                    required
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider class="mt-4 mb-2"></v-divider>
              <v-row justify="center" class="px-4">
                <v-col cols="7" class="pt-2">
                  <v-text-field
                    solo
                    flat
                    v-model="email"
                    :rules="emailRules"
                    placeholder="Email"
                    required
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-btn text color="primary" @click="getToPage('login')">
                    <p
                      class="subtitle-2 primary-color ma-auto font-weight-regular text-capitalize"
                    >
                      Punya Akun?
                    </p>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-text class="button">
            <v-row cols="8" align="center" justify="center">
              <v-btn
                :disabled="!valid"
                :formLoading="formLoading"
                block
                text
                type="submit"
                form="check-login-form"
              >
                <p class="ma-auto text-capitalize">
                  Kirim
                </p>
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from "@/services/resources/auth.service";
import { mapSummaryRootField } from "@/store/helpers";

export default {
  data() {
    return {
      version: process.env.VERSION ?? null,
      valid: true,
      formLoading: false,
      e1: true,
      username: null,
      usernameRules: [
        v => !!v || "Username is required",
        v => !!v || "Username harus diisi",
        v => /^\S{1,}$/.test(v) || "Format username harus sesuai"
      ],
      email: null,
      emailRules: [v => !!v || "Email is required"]
    };
  },
  computed: {
    ...mapSummaryRootField({
      isPresensi: "setting.isPresensi"
    })
  },
  methods: {
    onSubmit(username, email) {
      if (this.$refs.form.validate()) {
        this.forgotPassword({
          username,
          email
        });
      }
    },

    // Service
    async forgotPassword(payload) {
      try {
        this.formLoading = true;
        await AuthService.forgotPassword(payload)
          .then(response => {
            const { message, status } = response.data;
            if (status) {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
              this.getToPage("reset.password", {
                query: { username: this.username }
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            this.$store.commit("snackbar/setSnack", {
              show: true,
              message: error.response.data.message,
              color: "error"
            });
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>
<style>
.background {
  background-color: #297bbf;
  height: "100%";
}

.button {
  background-color: #fbb005;
}
</style>
